import {KurumEntity} from "@/entity/KurumEntity";
import {AdresListResponseProvider} from "@/services/AdresService";
import {IletisimBilgisiListResponseProvider} from "@/services/IletisimBilgisiService";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";
import {KamuOzel} from "@/enum/KamuOzel";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import { KisiListResponseProvider } from './KisiService';
import { KisiEntity } from '@/entity/KisiEntity';
import { HesapNumarasiEntity } from '@/entity/HesapNumarasiEntity';
import { AdresEntity } from '@/entity/AdresEntity';
import { IletisimBilgisiEntity } from '@/entity/IletisimBilgisiEntity';


export class KurumListResponse {
    data!: KurumEntity[];
}



export function KurumListResponseProvider(depth:number=3): KurumListResponse {
    let kisilerResponse = Array<KisiEntity>();
    let hesaplarResponse = Array<HesapNumarasiEntity>();
    let adreslerResponse = Array<AdresEntity>();
    let iletisimBilgileriResponse = Array<IletisimBilgisiEntity>();
    if(--depth > 0)
    {
        kisilerResponse = KisiListResponseProvider(depth).data;
        hesaplarResponse = BankaHesapListResponseProvider(depth).data;
        adreslerResponse = AdresListResponseProvider(depth).data;
        iletisimBilgileriResponse =  IletisimBilgisiListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                vergiNo: 10000000000,
                vergiDairesi: "Ankara Vergi Dairesi",
                ortaklar: kisilerResponse,
                ad: "Gediz Elektrik",
                ticaretSicilNo: 1,
                mersisNo: "2",
                kamuOzel: KamuOzel.ozel,
                kisiKurumTuru: KisiKurumTuru.sirket
            },
            {
                id: 2,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                vergiNo: 10000000002,
                vergiDairesi: "izmir Vergi Dairesi",
                ortaklar: kisilerResponse,
                ad: "Fenerbahçe Spor Klübü",
                ticaretSicilNo: 2,
                mersisNo: "2",
                kamuOzel: KamuOzel.kamu,
                kisiKurumTuru: KisiKurumTuru.sirket
            },
            {
                id: 3,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                vergiNo: 10000000002,
                vergiDairesi: "izmir Vergi Dairesi",
                ortaklar: kisilerResponse,
                ad: "Alliance Sigorta",
                ticaretSicilNo: 3,
                mersisNo: "2",
                kamuOzel: KamuOzel.ozel,
                kisiKurumTuru: KisiKurumTuru.sirket
            },
            {
                id:4,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                vergiNo: 8194125027,
                vergiDairesi: "Çorum V.D",
                ortaklar: kisilerResponse,
                ad: "FreemanSoft",
                ticaretSicilNo: 4,
                mersisNo: "2",
                kamuOzel: KamuOzel.ozel,
                kisiKurumTuru: KisiKurumTuru.sirket
            }
        ]
    }
}
