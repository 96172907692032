import {HesapNumarasiEntity} from "@/entity/HesapNumarasiEntity";
import {BankaListResponseProvider} from "@/services/BankaService";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import { BankaEntity } from '@/entity/BankaEntity';

class BankaHesapListResponse{
    data!: HesapNumarasiEntity[]
}

export function BankaHesapListResponseProvider(depth:number=3):BankaHesapListResponse {
    let bankaResponse = Array<BankaEntity>();
    let paraBirimiResponse = Array<ParaBirimiEntity>();
    if(--depth > 0)
    {
        bankaResponse = BankaListResponseProvider(depth).data;
        paraBirimiResponse = ParaBirimiListResponseProvider(depth).data;
    }
    return {
        data:[
            {
                id:1,
                banka: bankaResponse[1],
                paraBirimi: paraBirimiResponse[0],
                subeAdi: "Alsancak",
                hesapNo: "1821028",
                subeKodu: "0891",
                iban: "TR 81 9200 0024 0089 1000 0182 1024",
                ibanMi: false,
                hesapAdi: "Kaan Yatırım Hesap",
            },
            {
                id:2,
                banka: bankaResponse[0],
                paraBirimi: paraBirimiResponse[2],
                subeAdi:"",
                hesapNo:"",
                subeKodu:"",
                hesapAdi:"Kaan Maaş Hesap",
                iban:"TR 81 9200 0024 0089 1000 0182 1028",
                ibanMi:true,
            },

        ]
    }
}
