import {IlListResponseProvider} from "@/services/IlService";
import {IlceListResponseProvider} from "@/services/IlceService";
import {AvukatKimlikKartiEntity} from "@/entity/AvukatKimlikKartiEntity";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";
import { IlceEntity } from '@/entity/IlceEntity';


class AvukatKimlikKartiListResponse {
    data!: AvukatKimlikKartiEntity[]
}

export function AvukatKimlikKartiListResponseProvider(depth:number=3): AvukatKimlikKartiListResponse {
    let ilceResponse = Array<IlceEntity>();
    if(--depth > 0)
    {
        ilceResponse = IlceListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                barokartNo: "23234234",
                seri: "A123546",
                seriNo: 123546,
                babaAdi: "Ali",
                anneAdi: "Ayşe",
                kanGrubu: "AbRH+",
                ilce: ilceResponse[0],
                mahalleKoy: "Urla İzmir",
                ciltNo: 231546,
                aileSiraNo: 12397846,
                aciklamaVeNotlar: "Kaan adamsın kral",
                dosyalar: [],
                kimlikBelgesiTuru: KimlikBelgesiTuru.avukatKimlikKarti,
            }
        ]
    }
}













