import {AdresEntity} from '@/entity/AdresEntity';
import {IlceListResponseProvider} from "@/services/IlceService";
import {AdresTuru} from "@/enum/AdresTuru";
import { KisiListResponseProvider } from '@/services/KisiService';
import { KurumListResponseProvider } from '@/services/KurumService';
import { KurumEntity } from '@/entity/KurumEntity';
import { KisiEntity } from '@/entity/KisiEntity';
import { IlceEntity } from '@/entity/IlceEntity';

class adresListResponse{
    data!:AdresEntity[]
}

export function AdresListResponseProvider(depth:number=3):adresListResponse {
    let kisilerResponse = Array<KisiEntity>();
    let kurumlarResponse = Array<KurumEntity>();
    let ilcelerResponse = Array<IlceEntity>();
    if(--depth > 0)
    {
        kisilerResponse = KisiListResponseProvider(depth).data;
        kurumlarResponse = KurumListResponseProvider(depth).data;
        ilcelerResponse = IlceListResponseProvider(depth).data;
    }
    return {
        data:[
            {
                id:1,
                kisiKurum: kisilerResponse[0],
                adresTuru: AdresTuru.yurticiev,
                adresDetayi: "İzmir Urla İçmeler 1088 sokak Numara 5",
                ilce: ilcelerResponse[8],
                postaKodu: 35430,
                birincil: true
            },
            {
                id:2,
                kisiKurum: kurumlarResponse[0],
                adresTuru: AdresTuru.yurticiis,
                adresDetayi: "Ankara Kızılay Ataç sokak daire 5 ",
                ilce: ilcelerResponse[1],
                postaKodu: 60000,
                birincil: false
            }
        ]
    }
}
