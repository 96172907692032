import {EhliyetEntity} from "@/entity/EhliyetEntity";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";
import { IlceListResponseProvider } from './IlceService';
import { IlceEntity } from '@/entity/IlceEntity';

class ehliyetListResponse {
    data!: EhliyetEntity[]
}

export function EhliyetListResponseProvider(depth:number=3): ehliyetListResponse {
    let ilcelerResponse = Array<IlceEntity>();
    if(--depth > 0)
    {
        ilcelerResponse = IlceListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                ehliyetNo: "13254612",
                aciklamaVeNotlar: "SEBOOOO",
                dosyalar: [],
                kimlikBelgesiTuru: KimlikBelgesiTuru.ehliyet,
                ilce: ilcelerResponse[0],
                verildigiYer: ilcelerResponse[8],
            },
            {
                id: 2,
                ehliyetNo: "55558989885",
                aciklamaVeNotlar: "SEBOOOO",
                dosyalar: [],
                kimlikBelgesiTuru: KimlikBelgesiTuru.ehliyet,
                ilce: ilcelerResponse[1],
                verildigiYer: ilcelerResponse[7],
            },

        ]
    }
}
