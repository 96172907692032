import {PasaportListResponseProvider} from "@/services/PasaportService";
import {AvukatKimlikKartiListResponseProvider} from "@/services/AvukatKimlikKartService";
import {NufusCuzdaniListResponseProvider} from "@/services/NufusCuzdaniService";
import {EhliyetListResponseProvider} from "@/services/EhliyetService";
import { PasaportEntity } from '@/entity/PasaportEntity';
import { EhliyetEntity } from '@/entity/EhliyetEntity';
import { AvukatKimlikKartiEntity } from '@/entity/AvukatKimlikKartiEntity';
import { NufusCuzdaniEntity } from '@/entity/NufusCuzdaniEntity';
import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";


export interface KimlikBelgeleriResponse {
    data: Array<KimlikBelgesiEntity>;
}

export function KimlikBelgeleriResponseProvider(depth:number=3): KimlikBelgeleriResponse {
    let pasaportlarResponse = Array<PasaportEntity>();
    let ehliyetlerResponse = Array<EhliyetEntity>();
    let avukatKimlikKartlariResponse = Array<AvukatKimlikKartiEntity>();
    let nufusCuzdanlariResponse = Array<NufusCuzdaniEntity>();
    if(--depth > 0){
        pasaportlarResponse = PasaportListResponseProvider(depth).data;
        ehliyetlerResponse = EhliyetListResponseProvider(depth).data;
        avukatKimlikKartlariResponse = AvukatKimlikKartiListResponseProvider(depth).data;
        nufusCuzdanlariResponse = NufusCuzdaniListResponseProvider(depth).data;
    }
    let dataArray = Array <KimlikBelgesiEntity>();
    dataArray.push(pasaportlarResponse[0]);
    dataArray.push(ehliyetlerResponse[0]);
    dataArray.push(avukatKimlikKartlariResponse[0]);
    dataArray.push(nufusCuzdanlariResponse[0]);
    return {
        data: dataArray
    }
}