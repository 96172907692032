export enum KimlikBelgesiTuru {
    pasaport = "pasaport",
    nufusCuzdani = "nufusCuzdani",
    ehliyet = "ehliyet",
    avukatKimlikKarti = "avukatKimlikKarti",
}

export enum KimlikBelgesiTuruLabel {
    pasaport = "Pasaport",
    nufusCuzdani = "Nüfus Cüzdanı",
    ehliyet = "Ehliyet",
    avukatKimlikKarti = "Avukat Kimlik Kartı",
}
