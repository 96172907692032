import {PasaportEntity} from "@/entity/PasaportEntity";
import {PasaportTuru} from "@/enum/PasaportTuru";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";
import {IlListResponseProvider} from "@/services/IlService";

class PasaportListResponse {
    data!: PasaportEntity[]
}

export function PasaportListResponseProvider(depth:number=3): PasaportListResponse {
    return {
        data: [
            {
                id: 1,
                turu: PasaportTuru.siyah,
                uyrugu: "TC",
                pasaportNo: 123213,
                ulkeKodu: 35231,
                aciklamaVeNotlar: "SEBOOOO",
                dosyalar: [],
                kimlikBelgesiTuru: KimlikBelgesiTuru.pasaport,
                ilce: null,
            },
            {
                id: 2,
                turu: PasaportTuru.siyah,
                uyrugu: "ABD",
                pasaportNo: 555444,
                ulkeKodu: 111111,
                aciklamaVeNotlar: "SEBOOOO",
                dosyalar: [],
                kimlikBelgesiTuru: KimlikBelgesiTuru.pasaport,
                ilce:null,
            },

        ]
    }
}
