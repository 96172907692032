import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import {IletisimBilgisiTuru} from "@/enum/IletisimBilgisiTuru";
import { KisiListResponseProvider } from './KisiService';
import { KurumListResponseProvider } from './KurumService';
import { KisiEntity } from '@/entity/KisiEntity';
import { KurumEntity } from '@/entity/KurumEntity';

export class IletisimBilgisiListResponse {
    data!: IletisimBilgisiEntity[];
}

export function IletisimBilgisiListResponseProvider(depth:number=3): IletisimBilgisiListResponse {
    let kisilerResponse = Array<KisiEntity>();
    let kurumlarResponse = Array<KurumEntity>();
    if(--depth > 0)
    {    
        kisilerResponse = KisiListResponseProvider(depth).data;
        kurumlarResponse = KurumListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                kisiKurum: kisilerResponse[0],
                deger: "02329990666",
                tur: IletisimBilgisiTuru.telSabit,
            },
            {
                id: 2,
                kisiKurum: kisilerResponse[0],
                deger: "sebo@caliskan.com",
                tur: IletisimBilgisiTuru.eposta,
            },
            {
                id: 3,
                kisiKurum: kisilerResponse[0],
                deger: "https://www.wikipedia.com/",
                tur: IletisimBilgisiTuru.webSitesi,
            },
            {
                id: 4,
                kisiKurum: kurumlarResponse[0],
                deger: "05334300429",
                tur: IletisimBilgisiTuru.telMobil,
            },

        ]
    };
}

