import {KisiEntity} from "@/entity/KisiEntity";
import {Cinsiyet} from "@/enum/Cinsiyet";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";
import {IletisimBilgisiListResponseProvider} from "@/services/IletisimBilgisiService";
import {AdresListResponseProvider} from "@/services/AdresService";
import {IlListResponseProvider} from "@/services/IlService";
import { KurumListResponseProvider } from './KurumService';
import { KurumEntity } from '@/entity/KurumEntity';
import { HesapNumarasiEntity } from '@/entity/HesapNumarasiEntity';
import { AdresEntity } from '@/entity/AdresEntity';
import { IletisimBilgisiEntity } from '@/entity/IletisimBilgisiEntity';
import { IlEntity } from '@/entity/IlEntity';
import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";
import {KimlikBelgeleriResponseProvider} from "@/services/KimlikBelgeleriService";


export class KisiListResponse {
    data!: KisiEntity[];
}

 
export function KisiListResponseProvider(depth:number=7): KisiListResponse {
    let kurumlarResponse = Array<KurumEntity>();
    let hesaplarResponse = Array<HesapNumarasiEntity>();
    let adreslerResponse = Array<AdresEntity>();
    let iletisimBilgileriResponse =  Array<IletisimBilgisiEntity>();
    let kimlikBelgeleriResponse = Array<KimlikBelgesiEntity>();
    let illerResponse =  Array<IlEntity>();

    if(--depth > 0)
    {

        kurumlarResponse = KurumListResponseProvider(depth).data;
        hesaplarResponse = BankaHesapListResponseProvider(depth).data;
        adreslerResponse = AdresListResponseProvider(depth).data;
        iletisimBilgileriResponse =  IletisimBilgisiListResponseProvider(depth).data;
        illerResponse =  IlListResponseProvider(depth).data;
        kimlikBelgeleriResponse = KimlikBelgeleriResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                ad: "Şengül",
                soyad: "Doğan",
                adresler: adreslerResponse,
                tc: "11111111110",
                dogumYeri: illerResponse[0],
                dogumTarihi: new Date("2019-01-28 17:53"),
                cinsiyet: Cinsiyet.kadin,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                ortakOlduguKurumlar: kurumlarResponse,
                kisiKurumTuru: KisiKurumTuru.sahis,
                kimlikBelgeleri:kimlikBelgeleriResponse,
            },
            {
                id: 2,
                ad: "Ahmet",
                soyad: "Çatalca",
                adresler: adreslerResponse,
                tc: "11111111112",
                dogumYeri: illerResponse[1],
                dogumTarihi: new Date("1979-01-28 17:53"),
                cinsiyet: Cinsiyet.kadin,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                ortakOlduguKurumlar: kurumlarResponse,
                kisiKurumTuru: KisiKurumTuru.sahis,
                kimlikBelgeleri:kimlikBelgeleriResponse
            },
            {
                id: 3,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                ad: "Bilge",
                soyad: "Yılmaz",
                tc: '52420755262',
                dogumYeri: illerResponse[2],
                dogumTarihi: new Date("2001-10-02"),
                cinsiyet: Cinsiyet.kadin,
                kisiKurumTuru: KisiKurumTuru.sahis,
                ortakOlduguKurumlar: kurumlarResponse,
                kimlikBelgeleri:kimlikBelgeleriResponse
            },
            {
                id: 4,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                ad: "Maynard James",
                soyad: "Keenan",
                tc: '65202475528',
                dogumYeri: illerResponse[3],
                dogumTarihi: new Date("1964-11-21"),
                cinsiyet: Cinsiyet.erkek,
                kisiKurumTuru: KisiKurumTuru.sahis,
                ortakOlduguKurumlar: kurumlarResponse,
                kimlikBelgeleri:kimlikBelgeleriResponse
            },
            {
                id:5,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                hesaplar: hesaplarResponse,
                ad: "Hakan",
                soyad: "Aymaz",
                tc: '65202475528',
                dogumYeri: illerResponse[0],
                dogumTarihi: new Date("1973-11-21"),
                cinsiyet: Cinsiyet.erkek,
                kisiKurumTuru: KisiKurumTuru.sahis,
                ortakOlduguKurumlar: kurumlarResponse,
                kimlikBelgeleri:kimlikBelgeleriResponse
            }
        ]
    }
}
